const ready = (callback) => {
  if (document.readyState === 'complete'
    || (document.readyState !== 'loading' && !document.documentElement.doScroll)
  ) {
    callback();
  } else {
    document.addEventListener('DOMContentLoaded', callback);
  }
};

export default ready;
