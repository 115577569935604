import * as Sentry from '@sentry/browser';
// import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENV || 'development',
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP 'optimizing' proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // above come directly from sentry. the ones that follow are annoying ones we've seen
    '[object Response]',
    "Can't find variable: cordova",
    'cancelled',
    'Could not load the Google Maps API',
    'Failed to fetch',
    'Load failed',
    'NetworkError when attempting to fetch resource.',
    'Service Unavailable',
    'The operation was aborted.',
    'The string did not match the expected pattern.',
    'undefined is not an object',
  ],
  // integrations: [
  //   new BrowserTracing(),
  // ],

  // // Set tracesSampleRate to 1.0 to capture 100%
  // // of transactions for performance monitoring.
  // // We recommend adjusting this value in production
  // tracesSampleRate: 1.0,
});
