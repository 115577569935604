const idempotence = {
  guard(element, action) {
    if (element.getAttribute(`data-js-${action}-initialized`)) return true;
    element.setAttribute(`data-js-${action}-initialized`, true);
    return false;
  },

  remove(element, action) {
    element.removeAttribute(`data-js-${action}-initialized`);
  }
};

export default idempotence;
